/**
 * @generated SignedSource<<6bd5d23dfbc8fc7eb109223f8a357674>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type BulkCreateUsersInput = {|
  clientMutationId?: ?string,
  users?: ?$ReadOnlyArray<?GhostUserInputType>,
|};
export type GhostUserInputType = {|
  bio?: ?string,
  email?: ?string,
  first_name?: ?string,
  last_name?: ?string,
  phone?: ?string,
  skills?: ?$ReadOnlyArray<?string>,
  socialProfiles?: ?SocialProfilesInputType,
  website?: ?string,
|};
export type SocialProfilesInputType = {|
  behance?: ?string,
  buymeacoffee?: ?string,
  dribbble?: ?string,
  flickr?: ?string,
  github?: ?string,
  instagram?: ?string,
  linkedin?: ?string,
  medium?: ?string,
  patreon?: ?string,
  pinterest?: ?string,
  quora?: ?string,
  snapchat?: ?string,
  stackoverflow?: ?string,
  substack?: ?string,
  tiktok?: ?string,
  tumblr?: ?string,
  twitter?: ?string,
  vimeo?: ?string,
  youtube?: ?string,
|};
export type BulkCreateUsersMutation$variables = {|
  input: BulkCreateUsersInput,
|};
export type BulkCreateUsersMutation$data = {|
  +bulkCreateUsers: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +error: ?string,
      +index: ?number,
    |}>,
  |},
|};
export type BulkCreateUsersMutation = {|
  response: BulkCreateUsersMutation$data,
  variables: BulkCreateUsersMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkCreateUsersPayload",
    "kind": "LinkedField",
    "name": "bulkCreateUsers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BulkCreateUserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "index",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkCreateUsersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkCreateUsersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cbd895fe81391b0ace1836f408e4f027",
    "id": null,
    "metadata": {},
    "name": "BulkCreateUsersMutation",
    "operationKind": "mutation",
    "text": "mutation BulkCreateUsersMutation(\n  $input: BulkCreateUsersInput!\n) {\n  bulkCreateUsers(input: $input) {\n    errors {\n      index\n      error\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "873a256cd9b1768a4c78d0b8863c3e79";

module.exports = ((node/*: any*/)/*: Mutation<
  BulkCreateUsersMutation$variables,
  BulkCreateUsersMutation$data,
>*/);
